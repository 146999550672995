import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function LaminaLandingPage() {
    const navigate = useNavigate();

    // Logout function
    const handleLogout = async () => {
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/api/logout`, 
                {}, 
                { withCredentials: true }
            );
            navigate('/');
        } catch (error) {
            console.error("Failed to log out:", error);
        }
    };

    return (
        <div className="min-h-screen flex flex-col text-gray-400">
            <header className="flex justify-end p-6">
                <button
                    onClick={handleLogout}
                    className="px-4 py-2 bg-blue-400 text-white rounded hover:bg-blue-500 transition"
                >
                    Logout
                </button>
            </header>

            <main className="flex-grow text-center">
                <h1 className="text-4xl text-blue-400">Lamina</h1>
                <p className="mt-4">Access the tools and resources you need here.</p>
            </main>
        </div>
    );
}

export default LaminaLandingPage;
