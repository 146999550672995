import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import ProtectedRoute from './services/ProtectedRoute';
import LandingPage from './components/LandingPage';
import LaminaLandingPage from './components/LaminaLandingPage';
import EmailVerified from './components/EmailVerified';
import AboutMe from './components/AboutMe';
import Projects from './components/Projects';
import AboutLamina from './components/AboutLamina';
import Awards from './components/Awards';
import '@fontsource/inter';


function App() {
    return (
        <div className="font-inter">
<Router>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/about-me" element={<AboutMe />} />
                <Route path="/about-lamina" element={<AboutLamina />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/awards" element={<Awards />} />
                <Route path="/email-verified" element={<EmailVerified />} />
                <Route
                    path="/lamina"
                    element={
                        <ProtectedRoute>
                            <LaminaLandingPage />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </Router>
      </div>
        
    );
}

export default App;