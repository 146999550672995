import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function ProtectedRoute({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(null); // null for initial state
    const navigate = useNavigate();

    useEffect(() => {
        async function checkAuth() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/check-auth`, { withCredentials: true });
                setIsAuthenticated(response.data.isAuthenticated);
                if (!response.data.isAuthenticated) {
                    navigate('/login');
                }
            } catch (error) {
                setIsAuthenticated(false);
                navigate('/login');
            }
        }
        checkAuth();
    }, [navigate]);

    if (isAuthenticated === null) return null; // or a loading spinner

    return isAuthenticated ? children : null;
}

export default ProtectedRoute;
