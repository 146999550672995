import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { AdditiveBlending } from 'three';

const Galaxy = () => {
    const mountRef = useRef(null);

    useEffect(() => {
        const parameters = {
            count: 70000,
            size: 0.01,
            radius: 5,
            branches: 8,
            spin: 1,
            randomness: 0.3,
            randomnessPower: 5,
            insideColor: '#ff6030',
            outsideColor: '#1b3984'
        };

        const scene = new THREE.Scene();
        const canvas = mountRef.current;

        let geometry = null;
        let material = null;
        let points = null;

        const generateGalaxy = () => {
            if (points) {
                geometry.dispose();
                material.dispose();
                scene.remove(points);
            }

            geometry = new THREE.BufferGeometry();
            const positions = new Float32Array(parameters.count * 3);
            const colors = new Float32Array(parameters.count * 3);
            const colorInside = new THREE.Color(parameters.insideColor);
            const colorOutside = new THREE.Color(parameters.outsideColor);

            for (let i = 0; i < parameters.count; i++) {
                const radius = Math.random() * parameters.radius;
                const branchAngle = (i % parameters.branches) / parameters.branches * 2 * Math.PI;
                const spinAngle = radius * parameters.spin;
                const randomX = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : -1);
                const randomY = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : -1);
                const randomZ = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : -1);

                positions[i * 3] = Math.sin(branchAngle + spinAngle) * radius + randomX;
                positions[i * 3 + 1] = randomY;
                positions[i * 3 + 2] = Math.cos(branchAngle + spinAngle) * radius + randomZ;

                const mixedColor = colorInside.clone();
                mixedColor.lerp(colorOutside, radius / parameters.radius);

                colors[i * 3] = mixedColor.r;
                colors[i * 3 + 1] = mixedColor.g;
                colors[i * 3 + 2] = mixedColor.b;
            }

            geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
            geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));

            material = new THREE.PointsMaterial({
                size: parameters.size,
                depthWrite: false,
                blending: AdditiveBlending,
                vertexColors: true,
                transparent: true,
            });

            points = new THREE.Points(geometry, material);
            scene.add(points);
        };

        generateGalaxy();

        const sizes = {
            width: window.innerWidth,
            height: window.innerHeight
        };
        const camera = new THREE.PerspectiveCamera(85, sizes.width / sizes.height, 0.1, 150);
        camera.position.set(3, 3, 3);
        scene.add(camera);

        const renderer = new THREE.WebGLRenderer({ canvas, alpha: true });
        renderer.setSize(sizes.width, sizes.height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

        const clock = new THREE.Clock();
        const animate = () => {
            const elapsedTime = clock.getElapsedTime();
            points.rotation.y = elapsedTime * 0.3;
            renderer.render(scene, camera);
            requestAnimationFrame(animate);
        };
        animate();

        window.addEventListener('resize', () => {
            sizes.width = window.innerWidth;
            sizes.height = window.innerHeight;
            camera.aspect = sizes.width / sizes.height;
            camera.updateProjectionMatrix();
            renderer.setSize(sizes.width, sizes.height);
            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        });

        return () => {
            renderer.dispose();
        };
    }, []);

    return <canvas ref={mountRef} className="fixed top-0 left-0 w-full h-full -z-10" />;
};

export default Galaxy;
