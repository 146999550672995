import React from 'react';
import { Link } from 'react-router-dom';

const AboutLamina = () => {
  return (
    <div id="site-content" className="max-w-5xl mx-auto px-8 py-10 text-gray-800">

      <Link to="/" className="text-blue-400 underline mb-8 block">Back to Home</Link>

      <h1 className="text-4xl font-bold mb-4">About Lamina</h1>
      <p className="text-lg mb-6 italic" > 11/5/24: Lamina is still underconstruction, at this stage there is only account/email authentication.</p>
      <p className="text-lg mb-4">
        <strong>Lamina</strong> is a full-stack web application designed for foundation engineers and students to
        perform precise foundation calculations based on <em>Principles of Foundation Engineering</em>. Provides
        calculation tools, data storage, and open-source engineering resources.
      </p>

      <h2 className="text-3xl font-semibold mt-8 mb-4">Core Features</h2>
      <ul className="list-disc list-inside text-lg mb-8 space-y-3">
        <li>Foundation calculation tools based on established engineering principles.</li>
        <li>Database for storing and revisiting past calculations.</li>
        <li>Interactive interface for managing project-specific data inputs.</li>
        <li>Open-source machine learning models for engineering applications.</li>
      </ul>

      <h2 className="text-3xl font-semibold mt-10 mb-4">Technology Stack</h2>
      <p className="text-lg mb-8">
        Lamina uses React for frontend, 
        Node.js and Express for the backend API, and MongoDB (using Mongo Atlas) for storing user data. Deployed on DigitalOcean Linux-based virtual machines. Email authentication using Resend.
      </p>


      <h2 className="text-3xl font-semibold mt-10 mb-4">Open-Source Machine Learning</h2>
      <p className="text-lg mb-8">
        Lamina provides open-source machine learning models for foundation engineering applications. 
        Users can download the code and experiment with calculations locally, exploring predictive 
        analytics in their engineering workflows.
      </p>

      <div className="text-center mt-12 pb-5">
        <Link to="/register" className="bg-blue-500 text-white py-3 px-8  rounded-full shadow-lg hover:bg-blue-600">
          Get Started
        </Link>
      </div>
    </div>
  );
};

export default AboutLamina;
