import React from 'react'
import { Link } from 'react-router-dom'
const Projects = () => {
  return (
    <div className="max-w-3xl mx-auto px-10 py-10">
      <h3 id="projects" className="text-3xl font-bold mt-20 mb-10">projects.</h3>

      {/* Water Table Depth Prediction */}
      <div className="mb-10">
        <h4 className="text-2xl font-bold mb-4">Water Table Depth Prediction</h4>
        <p className="text-lg mb-4">
          Developed a machine learning model in Python to predict water table depth to assist with earthquake predictions in the U.S.
        </p>
        <p className="text-lg">Python - Pandas - PyTorch - sklearn - Numpy - geopy - geopandas - Earth Engine</p>
      </div>

      {/* Multi-Tumor Detection Project */}
      <div className="mb-10">
        <h4 className="text-2xl font-bold mb-4">Multi-Tumor Detection Project</h4>
        <p className="text-lg mb-4">
          Programmed a machine learning model in a group to locate tumors from CT scans in the liver.
        </p>
        <p className="text-lg">Python - TensorFlow - Keras - NumPy</p>
      </div>

      {/* Wind Load Calculation Desktop App */}
      <div className="mb-10">
        <h4 className="text-2xl font-bold mb-4">Wind Load Calculation Desktop App</h4>
        <p className="text-lg mb-4">
          Developed a desktop program for calculating wind loads on factory buildings, with 3D visualization.
        </p>
        <p className="text-lg">C# - WPF - XAML</p>
      </div>
      <Link to="/" className="text-blue-400 underline mt-10 block">Back to Home</Link>
    </div>
  )
}

export default Projects
