import React from 'react';
import { Link } from 'react-router-dom';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import Galaxy from './Galaxy';

function LandingPage() {
    return (
        <div className="relative font-roboto w-screen h-screen overflow-hidden">
            <Galaxy />
            <div className="absolute inset-0 flex flex-col justify-between z-10">
                <div className="absolute top-5 right-5 sm:right-10 sm:top-10 text-right text-sm md:text-lg">
                    <ul className="flex flex-col sm:flex-row sm:items-center">
                        <li className="my-2 sm:my-0 sm:mr-4">
                            <Link to="/about-me" className="hover:underline">about me</Link>
                        </li>
                        <li className="my-2 sm:my-0 sm:mr-4">
                            <Link to="/projects" className="hover:underline">projects</Link>
                        </li>
                        <li className="my-2 sm:my-0 sm:mr-4">
                            <Link to="/awards" className="hover:underline">awards</Link>
                        </li>
                        <li className="flex items-center space-x-4 mt-4 sm:mt-0">
                            <a
                                href="https://github.com/putt-t"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-xl sm:text-2xl md:text-3xl hover:text-gray-500"
                            >
                                <FaGithub />
                            </a>
                            <a
                                href="https://www.linkedin.com/in/nathaphat"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-xl sm:text-2xl md:text-3xl hover:text-blue-600"
                            >
                                <FaLinkedin />
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="absolute left-5 sm:left-10 top-5 sm:top-10 text-center sm:text-left">
                    <h1 className="text-3xl sm:text-3xl md:text-4xl font-mono overflow-hidden">
                        nathaphat.net
                    </h1>
                    <h2 className="text-xl sm:text-xl md:text-2xl mt-2 bg-gradient-to-r from-blue-500 to-purple-600 bg-clip-text text-transparent">
                        data science + web dev
                    </h2>
                </div>

                <div className="flex flex-col justify-center items-center h-screen px-4">
                    <div className="mt-[-10vh] text-center">
                        <h1 className="text-3xl sm:text-3xl md:text-5xl">
                            working on&nbsp;
                            <Link
                                to="/about-lamina"
                                className="bg-gradient-to-r from-blue-400 to-green-300 bg-clip-text text-transparent hover:text-blue-300 hover:underline transition duration-200"
                            >
                                Lamina
                            </Link>
                        </h1>

                        <p className="text-sm sm:text-sm md:text-base mt-4 italic">
                            a foundation engineering web app
                        </p>
                    </div>
                </div>
                <p className="absolute bottom-5 right-5 text-sm sm:text-sm md:text-sm text-gray-200 mt-4 italic">
                    galaxy inspired by a threejs course
                </p>
            </div>
        </div>
    );
}

export default LandingPage;
