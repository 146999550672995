import React from 'react'
import { Link } from 'react-router-dom'

const Awards = () => {
  return (
    <div className="max-w-3xl mx-auto px-10 py-20">
      <h3 id="awards" className="text-3xl font-bold mt-20 mb-10">awards.</h3>

      {/* Halıcıoğlu Data Science Institute - Research Scholarship Program */}
      <div className="mb-20">
        <h4 className="text-2xl font-bold mb-4">Halıcıoğlu Data Science Institute - Research Scholarship Program</h4>
        <p className="text-lg mb-4">
          Funded machine learning research project to predict engineering soil properties in points of interest using Gaussian Regression.
        </p>
        <p className="text-lg mt-4">
          Conducted sensitivity analysis, kernel testing, and cross-validation to find the best model. Produced a 3D soil map with soil type predictions utilizing soil behavior type charts.
        </p>
        <br/>
        <p className="text-lg">
          Python - Scikit-Learn - NumPy - Pandas
        </p>

        <Link to="/" className="text-blue-400 underline mt-10 block">Back to Home</Link>
      </div>
    </div>
  )
}

export default Awards
