import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading
        setErrorMessage(''); // Clear any previous error messages

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/login`,
                { username, password },
                { withCredentials: true }
            );

            if (response.data.message === 'Login successful!') {
                navigate('/lamina');
            }
        } catch (error) {
            setErrorMessage('Invalid login credentials');
            console.error(error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center px-4 text-gray-400">
            <form 
                onSubmit={handleLogin} 
                className="p-6 rounded-lg max-w-md w-full border border-gray-200 bg-white shadow-lg"
            >
                <h2 className="text-3xl text-center text-transparent bg-gradient-to-r from-green-400 to-purple-500 bg-clip-text mb-6 ">
                Login
                </h2>
                
                {errorMessage && (
                    <p className="text-red-500 text-center mb-4 text-sm sm:text-base">{errorMessage}</p>
                )}

                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                    required
                    className="w-full p-3 mb-4 rounded-lg text-gray-700 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                    className="w-full p-3 mb-4 rounded-lg text-gray-700 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />

                <button
                    type="submit"
                    className={`w-full p-3 rounded-lg text-white bg-blue-400 hover:bg-blue-500 transition duration-200 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={loading}
                >
                    {loading ? 'Logging in...' : 'Login'}
                </button>

                <p className="mt-4 text-center text-sm sm:text-base">
                    Don’t have an account?{' '}
                    <Link to="/register" className="text-blue-500 hover:underline">
                        Register here
                    </Link>
                </p>
            </form>
        </div>
    );
}

export default Login;
