import React from 'react';
import { Link } from 'react-router-dom';

const AboutMe = () => {
  return (
    <div id="site-content" className="max-w-3xl mx-auto px-4 py-5 md:px-8">

      <h3 id="about-me" className="text-2xl font-bold mt-20 mb-6 sm:text-xl md:text-3xl">
        about me.
      </h3>

      <p className="text-base sm:text-lg md:text-xl mb-6 text-center md:text-left">
        Hi, I'm Nathaphat, a third-year Data Science major at the University of California, San Diego. 
        I enjoy developing models and building web and desktop applications to help solve real-world problems.
      </p>


      <div className="mt-8">
        <h4 className="text-lg font-semibold mb-4 text-center md:text-left">Currently Listening To:</h4>
        <iframe
          title="Spotify Song"
          style={{ borderRadius: '12px' }}
          src="https://open.spotify.com/embed/track/6jbYpRPTEFl1HFKHk1IC0m?utm_source=generator"
          width="100%"
          height="152"
          className="shadow-lg"
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      </div>

      <Link to="/" className="text-blue-400 underline mt-10 block text-center md:text-left">
        Back to Home
      </Link>
    </div>
  );
};

export default AboutMe;
