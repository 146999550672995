import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function EmailVerified() {
    const [message, setMessage] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const status = query.get('status');

        if (status === 'success') {
            setMessage('Your email has been verified successfully! You can now log in.');
        } else if (status === 'expired') {
            setMessage('Your verification link has expired. Please request a new one.');
        } else {
            setMessage('There was an error verifying your email. Please try again.');
        }

        const timer = setTimeout(() => {
            navigate('/login');
        }, 5000); // Redirect after 5 seconds

        return () => clearTimeout(timer);
    }, [location, navigate]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-900 text-gray-400">
            <div className="bg-gray-800 p-8 rounded-lg max-w-md w-full shadow-lg">
                <h2 className="text-3xl text-center font-semibold text-white mb-4">Email Verification</h2>
                <p className="text-center text-gray-300 mb-6">{message}</p>
                <p className="text-center text-sm text-gray-500 mb-4">You will be redirected shortly...</p>
                <button
                    onClick={() => navigate('/login')}
                    className="w-full bg-blue-500 p-3 rounded-lg text-white hover:bg-blue-600 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    Go to Login
                </button>
            </div>
        </div>
    );
}

export default EmailVerified;
