import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    setErrorMessage('');

    // Check for username and password validity
    if (username.length < 3 || username.length > 10 || !/^[a-zA-Z0-9]+$/.test(username)) {
        setErrorMessage('Username must be 3-10 characters long and alphanumeric.');
        setLoading(false);
        return;
    }
    if (password.length < 12) {
        setErrorMessage('Password must be at least 12 characters long.');
        setLoading(false);
        return;
    }

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/register`,
            {
                username,
                password,
                email,
            },
            { withCredentials: true }
        );

        if (response.data.message === 'Verification email sent! Please check your inbox.') {
            setMessage('Account created! Please check your email to verify your account.');
        }
    } catch (error) {
        setErrorMessage(error.response?.data?.errors?.[0] || 'Registration failed');
        console.error(error);
    } finally {
        setLoading(false);
    }
};

  return (
    <div className="min-h-screen flex items-center justify-center px-4 text-gray-400 ">
      <form onSubmit={handleRegister} className=" p-8 rounded-lg shadow-lg max-w-md w-full border border-gray-200 focus:outline-none">
        <h2 className="text-3xl text-center text-transparent bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text mb-6 ">
          Register
        </h2>

        {errorMessage && (
          <p className="text-red-500 text-center mb-4">{errorMessage}</p>
        )}

        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          required
          className="w-full p-3 mb-4 rounded-lg text-black border border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
          className="w-full p-3 mb-4 rounded-lg  text-black border border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
          className="w-full p-3 mb-4 rounded-lg  text-black border border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        <button
          type="submit"
          className="w-full bg-blue-400 p-3 rounded-lg text-white hover:bg-blue-500 transition duration-200"
          disabled={loading}
        >
          {loading ? 'Registering...' : 'Register'}
        </button>
        <Link to="/login" className="text-blue-400 underline mb-8 mt-3 block ">Already have an account?</Link>

        {message && (
          <p className="mt-4 text-center text-sm text-green-400">
            {message}
          </p>
        )}
      </form>
    </div>
  );
}

export default Register;
